import React from 'react'
import ChoiseButton from '../buttonComponent/Button'
import './getGiftStyle.css'
import Swal from 'sweetalert2'
import {useAuth} from '../../context/contextProvider'
import { navigate } from 'gatsby-link'

const buttonStyle = {
    height : "50px", background : "#DD1D21", color: "#FBFBFB", width : "100px", fontSize: "17px"
}
function GetGift(props) {
    const auth = useAuth();
    function getScratch () {
    Swal.fire({
            html:
                `<img src=${props.link}>` + 
                `<div> <h2>${props.title}</h2> <h4>Description :</h4>${props.description}</div>` ,
            // text: "<img src='"+Image1+"' style='width:150px;'>",
            confirmButtonText : 'Suivant',
            customClass: {
                confirmButton: 'swalButton' //insert class here
            },
            confirmButtonColor : '#dd1d21',
            // html: true,
          }).then((result) => {
            if (result.isConfirmed)
            {
                auth.setglobalinfo({...auth.globalinfo, 
                    productName : props.title,
                    oilsKm : props.km,
                })
                auth.setstatus(2)
                navigate('/steps')
            }
          })
    }
    return (
        <div className="choiseStyle">
            <img src={props.link} className="imageStyle"/>
            <div className="descriptionStyle">
                <h4>{props.title}</h4>
                <ChoiseButton name="Choisir" Theme={buttonStyle} verify={() => getScratch()}/>
            </div>
        </div>
    )
}
export default GetGift