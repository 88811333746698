import React ,{useEffect, useState}from 'react'
import '../styles/gift.css'
import { useAuth } from '../context/contextProvider'
import GiftComponent from '../components/getGiftComponent/getGift'
import { navigate } from "gatsby-link"
import {GetProducts} from '../firebase/getProducts'

function Gift() {
    const auth = useAuth()
    const [Products, setProducts] = useState()
    useEffect(() => {
       if (auth.status !== 1)
       {
           navigate('/')
       }
       const Data = GetProducts()
       Data.then(res => {
          setProducts(res)
       })
    }, [])
    return (
        <div className="gift">
            <div className="title">
                <h1>Participez au jeu virtuel</h1>
                <h4>Pour participer au jeu virtuel, renseignez l'huile moteur choisie pour votre vidange.</h4>
            </div>
            <td />
            <div className="giftStyle">
            {
                Products?.map((product, index) => (
                <GiftComponent key={index} title={product.name} description={product.description} link={product.url} km={product.km} />
            ))}
            </div>
        </div>
    )
}
export default Gift
